import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';
import {htmlToReact} from '../utils';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Index extends React.Component {
    constructor(props) {
      super(props);
      this.state = { stats: undefined };
    }

    componentDidMount() {
      // fetch('https://api.skarga.help/stats')
      fetch('https://api.skarga.help/stats')
        .then(response => response.json())
        .then(json => this.setState({ stats: json }))
    }

    render() {
        return (
            <Layout {...this.props}>
            {_.map(_.get(this.props, 'pageContext.frontmatter.sections', null), (section, section_idx) => {
                let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
                let Component = components[component];
                return (
                  <Component key={section_idx} {...this.props} section={section} site={this.props.pageContext.site} />
                )
            })}
              <div>
                <div className="post-content">
                  {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                </div>
                {this.state.stats && this.statsBlock()}
                <section id="text-no-img" class="block block-text outer" style={{paddingBottom: 0, paddingTop: '3.75em'}}>
                  <div class="inner">
                    <div class="grid grid-middle grid-center">
                      <div class="grid-item block-body">
                        <div class="block-buttons"><a class="button" href="/app">Создать скаргу</a></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

            </Layout>
        );
    }

    statsBlock() {
      return(
        <section id="cta" class="block block-cta outer">
          <div class="inner">
            <div class="has-gradient">
              <div class="grid grid-middle grid-center">
                <div class="grid-item block-header">
                  <div style={{textAlign: 'center'}}>
                    <div style={{fontSize: '4em'}}>{this.state.stats?.all}</div>
                    <div style={{fontSize: '1.3em'}}>столько уникальных обращений уже создано</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
}
